<template class="mr-3">
  <div class="card">
    <div class="card-header">
      <div class="text-center">
        <div class="font-size-18 mb-3">
          <i class="fa fa-briefcase"></i>
          {{ $t("menu.listeEnseignants") }}
        </div>
      </div>
    </div>
    <div class="card-body">
      <div>
        <a-button
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <a-button type="user-add" @click="showModal">
          <a-icon type="user-add" @click="showModal" />
          {{ $t("liste.ajouterEnseignant") }}
        </a-button>
        <div>
          <a-modal
            :visible="visibleData"
            @cancel="handleCancel"
            :width="980"
            :title="$t('all.enterInformationBelow')"
            :footer="false"
          >
            <a-form :form="form" class="container" @submit="addEnseignant">
              <fieldset>
                <legend>{{ $t("personnel.infoBase") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.nom')">
                      <a-input
                        :placeholder="$t('profil.nom')"
                        v-decorator="[
                          'lastName',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.nom'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.prenom')">
                      <a-input
                        :placeholder="$t('profil.prenom')"
                        v-decorator="[
                          'firstName',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.prenomPere'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.dateNaissance')">
                      <a-date-picker
                        v-decorator="['birthDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.cin')">
                      <a-input
                        :placeholder="$t('personnel.cin')"
                        type="number"
                        v-decorator="[
                          'cin',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.cin'),
                              },
                              {
                                len: 8,
                                message: $t('requis.cin8'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.adresse')">
                      <a-input
                        :placeholder="$t('profil.adresse')"
                        v-decorator="['adress']"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('liste.telephone')">
                      <a-input
                        :placeholder="$t('liste.telephone')"
                        type="number"
                        v-decorator="[
                          'phone',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.telephone'),
                              },
                              {
                                len: 8,
                                message: $t('requis.telephone'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.etatCivil')">
                      <a-input
                        :placeholder="$t('personnel.etatCivil')"
                        v-decorator="['civilStatus']"
                      />
                    </a-form-item>
                  </div>

                  <div class="col-md-4">
                    <a-form-item :label="$t('enseignant.needValidation')">
                      <a-select v-decorator="['needValidation']">
                        <a-select-option value="false">{{
                          $t("all.oui")
                        }}</a-select-option>
                        <a-select-option value="true">{{
                          $t("all.non")
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.niveau") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.niveau')">
                      <a-input
                        :placeholder="$t('personnel.niveau')"
                        v-decorator="['level']"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-8">
                    <a-form-item :label="$t('personnel.experiences')">
                      <a-input
                        :placeholder="$t('personnel.experiences')"
                        type="textarea"
                        v-decorator="[
                          'experiences',
                          {
                            rules: [
                              {
                                required: false,
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("enseignant.detailPedagogiques") }}:</legend>

                <div class="row">
                  <div class="col-md-8">
                    <a-form-item
                      :label="$t('scolarite.affectationDansClasses') + ' :'"
                    >
                      <a-select
                        mode="multiple"
                        v-decorator="[
                          'classrooms',
                          {
                            rules: [
                              {
                                required: false,
                              },
                            ],
                          },
                        ]"
                        show-search
                        :allowClear="true"
                        :getPopupContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                        :placeholder="$t('paiement.listeClasses')"
                        style="width: 100%"
                        option-filter-prop="children"
                        :filter-option="filterOption"
                      >
                        <a-select-option
                          v-for="classe in classesData"
                          :key="classe._id"
                          :value="classe._id"
                          >{{ classe.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('emploi.matiere') + ' :'">
                      <a-select
                        mode="multiple"
                        v-decorator="[
                          'subjects',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.matiere'),
                              },
                            ],
                          },
                        ]"
                        show-search
                        :allowClear="true"
                        :getPopupContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                        :placeholder="$t('scolarite.listeMatieres')"
                        style="width: 100%"
                        option-filter-prop="children"
                        :filter-option="filterOption"
                      >
                        <a-select-option
                          v-for="subject in subjectsList"
                          :key="subject._id"
                          :value="subject._id"
                          >{{ subject.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('enseignant.specialite')">
                      <a-input
                        :placeholder="$t('enseignant.specialite')"
                        v-decorator="['speciality']"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('enseignant.grade')">
                      <a-input
                        :placeholder="$t('enseignant.grade')"
                        type="number"
                        v-decorator="[
                          'grade',
                          {
                            initialValue: '0',
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.infoContrat") }}:</legend>
                <div class="row">
                  <!-- <div class="col-md-4">
                    <a-form-item :label="$t('personnel.qualification')">
                      <a-select
                        v-decorator="['position']"
                        show-search
                        :allowClear="true"
                        :placeholder="$t('personnel.qualification')"
                        style="width: 100%"
                        option-filter-prop="children"
                      >
                        <a-select-option
                          v-for="position in positionsList"
                          :key="position._id"
                          :value="position._id"
                        >
                          {{ position.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>-->
                  <div class="col-md-6">
                    <a-form-item :label="$t('personnel.typeContrat')">
                      <a-input
                        :placeholder="$t('personnel.typeContrat')"
                        v-decorator="['contract']"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-6">
                    <a-form-item :label="$t('personnel.salaire') + ' (net)'">
                      <a-input
                        :placeholder="$t('personnel.salaire') + ' (net)'"
                        type="number"
                        v-decorator="['salary.net']"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.dateEntretien')">
                      <a-date-picker
                        v-decorator="['interviewDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('all.startDate')">
                      <a-date-picker
                        v-decorator="['StartDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('all.endDate')">
                      <a-date-picker
                        v-decorator="['endDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("enseignant.compte") }}</legend>
                <div class="row">
                  <div class="col-md-6">
                    <a-form-item :label="$t('enseignant.usernameEmail')">
                      <a-input
                        :placeholder="$t('enseignant.usernameEmail')"
                        v-decorator="[
                          'email',
                          {
                            rules: [
                              {
                                message: $t('requis.usernameEmail'),
                                required: true,
                              },
                              {
                                validator: validateNoWhitespace,
                                message: $t('requis.noWhitespace'),
                              },
                            ],
                          },
                        ]"
                      >
                        <a-icon
                          slot="prefix"
                          type="user"
                          style="color: rgba(0, 0, 0, 0.25)"
                        />
                      </a-input>
                    </a-form-item>
                  </div>
                  <div class="col-md-6">
                    <a-form-item :label="$t('profil.mdp')">
                      <a-input
                        :placeholder="$t('profil.mdp')"
                        v-decorator="[
                          'password',
                          {
                            initialValue: GeneratedPassword,
                            rules: [
                              {
                                message: $t('requis.mdp'),
                                required: true,
                              },
                              {
                                message: $t('requis.carac'),
                                min: 4,
                              },
                            ],
                          },
                        ]"
                      >
                        <a-icon
                          slot="prefix"
                          type="lock"
                          style="color: rgba(0, 0, 0, 0.25)"
                        />
                      </a-input>
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.detailPaiement") }}:</legend>
                <div class="row">
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.tauxHoraire')">
                      <a-input
                        :placeholder="$t('personnel.tauxHoraire')"
                        type="number"
                        v-decorator="[
                          'hourlyRate',
                          {
                            initialValue: 0,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.jourSemaine')">
                      <a-input
                        :placeholder="$t('personnel.jourSemaine')"
                        type="number"
                        v-decorator="[
                          'dayOfWeek',
                          {
                            initialValue: 0,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.cnss')">
                      <a-input
                        :placeholder="$t('personnel.cnss')"
                        type="number"
                        v-decorator="[
                          'CNSS',
                          {
                            initialValue: 0,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.rib')">
                      <a-input
                        :placeholder="$t('personnel.rib')"
                        v-decorator="[
                          'RIB',
                          {
                            initialValue: '0',
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.autre") }}:</legend>
                <div class="row">
                  <a-form-item :label="$t('profil.notes')" class="col-md-12">
                    <a-input
                      :placeholder="$t('profil.notes')"
                      type="textarea"
                      v-decorator="['notes']"
                    />
                  </a-form-item>
                </div>
              </fieldset>
              <div class="form-actions mt-0 row">
                <div class="col-md-2 offset-md-8 p-0">
                  <a-button
                    key="back"
                    @click="handleCancel"
                    :disabled="loadingEnseignant"
                    >{{ $t("action.annuler") }}</a-button
                  >
                </div>
                <div class="col-md-2 p-0">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    :disabled="loadingEnseignant"
                    :loading="loadingEnseignant"
                    >{{ $t("action.ajouter") }}</a-button
                  >
                </div>
              </div>
            </a-form>
          </a-modal>

          <!-- UPDATE MODAL --->
          <a-modal
            v-model="visibleUpdateData"
            @cancel="handleCancel"
            :width="980"
            :title="$t('all.enterInformationBelow')"
            :footer="false"
          >
            <a-form
              :form="updateForm"
              class="container"
              @submit="updateEnseignant"
            >
              <fieldset>
                <legend>{{ $t("personnel.infoContrat") }}:</legend>
                <div class="row">
                  <div class="col-md-6">
                    <a-form-item :label="$t('emploi.matiere') + ' :'">
                      <a-select
                        mode="multiple"
                        v-decorator="[
                          'subjects',
                          {
                            initialValue: updateData.subs,
                            rules: [
                              {
                                required: true,
                                message: $t('requis.matiere'),
                              },
                            ],
                          },
                        ]"
                        show-search
                        :allowClear="true"
                        :placeholder="$t('scolarite.listeMatieres')"
                        style="width: 100%"
                        option-filter-prop="children"
                      >
                        <a-select-option
                          v-for="subject in subjectsList"
                          :key="subject._id"
                          :value="subject._id"
                          >{{ subject.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-md-6">
                    <a-form-item :label="$t('enseignant.specialite')">
                      <a-input
                        :placeholder="$t('enseignant.specialite')"
                        v-decorator="[
                          'speciality',
                          {
                            initialValue: updateData.speciality,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <a-form-item :label="$t('enseignant.grade')">
                      <a-input
                        :placeholder="$t('enseignant.grade')"
                        type="number"
                        v-decorator="[
                          'grade',
                          {
                            initialValue: updateData.grade,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-6">
                    <a-form-item :label="$t('enseignant.needValidation')">
                      <a-select
                        v-decorator="[
                          'needValidation',
                          {
                            initialValue: updateData.needValidation,
                          },
                        ]"
                      >
                        <a-select-option value="false">{{
                          $t("all.oui")
                        }}</a-select-option>
                        <a-select-option value="true">{{
                          $t("all.non")
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <div class="form-actions mt-0 row">
                <div class="col-md-2 offset-md-8 p-0">
                  <a-button
                    key="back"
                    @click="handleCancel"
                    :disabled="loadingEnseignant"
                    >{{ $t("action.annuler") }}</a-button
                  >
                </div>
                <div class="col-md-2 p-0">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    :disabled="loadingEnseignant"
                    :loading="loadingEnseignant"
                    >{{ $t("action.modifier") }}</a-button
                  >
                </div>
              </div>
            </a-form>
          </a-modal>

          <!-- Profile MODAL --->
          <a-drawer
            title="Profile"
            placement="right"
            :closable="true"
            :visible="visibleProfileData"
            @close="handleCancel"
            :width="600"
          >
            <a-form class="container">
              <fieldset>
                <legend>{{ $t("personnel.infoBase") }}:</legend>
                <div class="row">
                  <div class="col-md-3">
                    <strong>{{ $t("enseignant.prenom") }}:</strong>
                    {{
                      updateData.employee ? updateData.employee.firstName : null
                    }}
                  </div>
                  <div class="col-md-3">
                    <strong>{{ $t("enseignant.nom") }}:</strong>
                    {{
                      updateData.employee ? updateData.employee.lastName : null
                    }}
                  </div>
                  <div class="col-md-6">
                    <strong>{{ $t("enseignant.dateNaissance") }}:</strong>
                    {{
                      updateData.employee
                        ? moment(updateData.employee.birthDate).format(
                            "DD-MM-YYYY"
                          )
                        : null
                    }}
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-3">
                    <strong>{{ $t("enseignant.cin") }}:</strong>
                    {{ updateData.employee ? updateData.employee.cin : null }}
                  </div>
                  <div class="col-md-3">
                    <strong>{{ $t("enseignant.adresse") }}:</strong>
                    {{
                      updateData.employee ? updateData.employee.adress : null
                    }}
                  </div>
                  <div class="col-md-6">
                    <strong>{{ $t("enseignant.telephone") }}:</strong>
                    {{ updateData.employee ? updateData.employee.phone : null }}
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <strong>{{ $t("enseignant.etatCivil") }}:</strong>
                    {{
                      updateData.employee
                        ? updateData.employee.civilStatus
                        : null
                    }}
                  </div>
                </div>
              </fieldset>
              <fieldset class="mt-4">
                <legend>{{ $t("personnel.niveau") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <strong>{{ $t("enseignant.niveau") }}:</strong>
                    {{ updateData.employee ? updateData.employee.level : null }}
                  </div>
                  <div class="col-md-8">
                    <strong>{{ $t("enseignant.experiences") }}:</strong>
                    {{
                      updateData.employee
                        ? updateData.employee.experiences
                        : null
                    }}
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="profileDataLoading" class="mt-4">
                <legend>{{ $t("personnel.infoContrat") }}:</legend>
                <a-col :span="16" :offset="11">
                  <a-spin size="large" />
                </a-col>
              </fieldset>
              <fieldset v-else class="mt-4">
                <legend>{{ $t("personnel.infoContrat") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <strong>{{ $t("enseignant.qualifications") }}:</strong>
                    {{
                      updateData.employee && updateData.employee.position
                        ? updateData.employee.position.name
                        : null
                    }}
                  </div>
                  <div class="col-md-4">
                    <strong>{{ $t("enseignant.typeContrat") }}:</strong>
                    {{
                      updateData.employee ? updateData.employee.contract : null
                    }}
                  </div>
                  <div class="col-md-4">
                    <strong>{{ $t("enseignant.salaire") }}(net):</strong>
                    {{
                      updateData.employee &&
                      updateData.employee.salary &&
                      updateData.employee.salary.net
                        ? updateData.employee.salary.net
                        : null
                    }}
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <strong>{{ $t("etablissement.classes") }}:</strong>
                    <div
                      class="ml-4"
                      v-for="classroom in classroomAffectationProfile"
                      :key="classroom._id"
                    >
                      - {{ classroom.name }}
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <strong>{{ $t("enseignant.matiere") }}</strong>
                    <div
                      class="ml-4"
                      v-for="subject in updateData.subjects"
                      :key="subject._id"
                    >
                      - {{ subject.name }}
                    </div>
                  </div>
                  <div class="col-md-4">
                    <strong>{{ $t("enseignant.specialite") }}:</strong>
                    {{ updateData.speciality }}
                  </div>
                  <div class="col-md-4">
                    <strong>{{ $t("enseignant.grade") }}:</strong>
                    {{ updateData.grade }}
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <strong>{{ $t("enseignant.dateEntretien") }}:</strong>
                    {{
                      updateData.employee
                        ? moment(updateData.employee.interviewDate).format(
                            "DD-MM-YYYY"
                          )
                        : null
                    }}
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <strong>{{ $t("all.startDate") }}:</strong>
                    {{
                      updateData.employee
                        ? moment(updateData.employee.StartDate).format(
                            "DD-MM-YYYY"
                          )
                        : null
                    }}
                  </div>
                  <div class="col-md-6">
                    <strong>{{ $t("all.endDate") }}:</strong>
                    {{
                      updateData.employee
                        ? moment(updateData.employee.endDate).format(
                            "DD-MM-YYYY"
                          )
                        : null
                    }}
                  </div>
                </div>
              </fieldset>
              <fieldset class="mt-4">
                <legend>{{ $t("personnel.detailPaiement") }}:</legend>
                <div class="row">
                  <div class="col-md-6">
                    <strong>{{ $t("enseignant.tauxHoraire") }}:</strong>
                    >{{
                      updateData.employee
                        ? updateData.employee.hourlyRate
                        : null
                    }}
                  </div>
                  <div class="col-md-6">
                    <strong>{{ $t("enseignant.jourSemaine") }}:</strong>
                    {{
                      updateData.employee ? updateData.employee.dayOfWeek : null
                    }}
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <strong>{{ $t("enseignant.cnss") }}:</strong>
                    {{ updateData.employee ? updateData.employee.CNSS : null }}
                  </div>
                  <div class="col-md-6">
                    <strong>{{ $t("enseignant.rib") }}:</strong>
                    {{ updateData.employee ? updateData.employee.RIB : null }}
                  </div>
                </div>
              </fieldset>
              <fieldset class="mt-4">
                <legend>{{ $t("personnel.autre") }}:</legend>
                <div class="row">
                  <strong>{{ $t("enseignant.notes") }}:</strong>
                  {{ updateData.employee ? updateData.employee.notes : null }}
                </div>
              </fieldset>
            </a-form>
          </a-drawer>
        </div>
      </div>
      <div>
        <a-table
          :pagination="true"
          :data-source="data"
          :loading="tableLoading"
          :columns="columns"
          :scrollToFirstRowOnChange="false"
          :customRow="
            (record) => {
              return {
                on: {
                  click: () => {
                    profile(record);
                  },
                },
              };
            }
          "
          rowKey="_id"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="avatar" slot-scope="text, record">
            <a-avatar
              size="large"
              v-if="record.employee.photo"
              :src="settings.base_url + '/' + record.employee.photo"
            />
            <a-avatar
              size="large"
              style="color: #f56a00; background-color: #fde3cf"
              v-else
              >{{ record.employee.firstName[0].toUpperCase() }}</a-avatar
            >
          </template>

          <template slot="StartDate" slot-scope="text, record">{{
            moment(record.StartDate).format("DD/MM/YYYY")
          }}</template>
          <template slot="endDate" slot-scope="text, record">
            {{
              record.endDate
                ? moment(record.endDate).format("DD/MM/YYYY")
                : "Non précisé"
            }}
          </template>
          <template slot="password" slot-scope="text, record">
            <a-button @click.stop="() => updatePassword(record)">
              <a-icon type="login" />
              {{ $t("action.modifier") }}
            </a-button>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => save(record.key)">{{ $t("all.save") }}</a>
                <a-popconfirm
                  title="Sure to cancel?"
                  @confirm="() => cancel(record.key)"
                >
                  <a>{{ $t("action.annuler") }}</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a-button
                  type="primary"
                  :disabled="editingKey !== ''"
                  @click.stop="() => edit(record)"
                >
                  <a-icon
                    type="edit"
                    :disabled="editingKey !== ''"
                    @click.stop="() => edit(record)"
                  />
                  {{ $t("action.modifier") }}
                </a-button>
              </span>
            </div>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="subject" slot-scope="text, record">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="subject in record.subjects"
                :key="subject.is"
              >
                {{ subject.name }}
              </li>
            </ul>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              title="Sure to delete?"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger" @click.stop>
                <a-icon type="delete" href="javascript:;" />
                {{ $t("action.supprimer") }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
        <update-password
          :key="activeTeacherId"
          :visibleModalPassword="visibleModalPassword"
          :idTeacher="activeTeacherId"
          :firstName="activeTeacher.employee.firstName"
          :lastName="activeTeacher.employee.lastName"
          :credentials="changePasswordProps"
          @cancel="() => (visibleModalPassword = false)"
        />
      </div>
    </div>
  </div>
  <!--les conseillées pédagogiques-->
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import moment from "moment";
import updatePassword from "./updatePassword.vue";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  computed: mapState(["settings"]),
  components: {
    updatePassword,
  },
  async created() {
    this.$on("cancel", () => {
      this.visibleModalPassword = false;
    });
    this.tableLoading = true;
    await apiClient
      .post("/teachers/filter", {
        query: {
          role: "teacher",
          status: "active",
        },
      })
      .then((res) => {
        this.data = res.data.map((elem) => ({ ...elem, key: elem._id }));
      })
      .catch((e) => {
        this.$message.error(this.$t("error.aucEnseignant"));
        console.log(e);
      });
    this.cacheData = this.data.map((item) => ({ ...item }));

    // Get positions List
    apiClient
      .post("/positions/filter", { query: { status: "active" } })
      .then((res) => (this.positionsList = res.data))
      .catch(() => {});

    // Get subject List
    apiClient
      .post("/subject/filter", { query: { status: "active" } })
      .then((res) => (this.subjectsList = res.data))
      .catch(() => {});

    apiClient
      .post("/classrooms/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        this.classesData = [...res.data];
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.erreur"));
      });

    this.tableLoading = false;
  },
  data() {
    return {
      profileDataLoading: false,
      classroomAffectationProfile: [],
      classesData: [],
      positionsList: [],
      subjectsList: [],
      dateFormat: "DD/MM/YYYY",
      editingKey: "",
      tableLoading: false,
      visibleParentFile: false,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      form: this.$form.createForm(this),
      updateForm: this.$form.createForm(this),
      updateData: {
        _id: "",
        cin: 0,
        adress: "",
        phone: 0,
        civilStatus: "",
        level: "",
        experiences: "",
        position: { _id: "" },
        contract: "",
        salary: { net: 0 },
        isA_Teacher: false,
        interviewDate: "",
        StartDate: "",
        endDate: "",
        hourlyRate: 0,
        dayOfWeek: 0,
        CNSS: 0,
        RIB: 0,
        notes: "",
        email: "",
        tp: "",
        needValidation: true,
      },
      value: 1,
      value1: 1,
      loadingEnseignant: false,
      visibleData: false,
      visibleUpdateData: false,
      visibleProfileData: false,
      columns: [
        {
          title: this.$t("enseignant.photo"),
          dataIndex: "employee.photo",
          key: "avatar",
          width: "auto",
          scopedSlots: {
            customRender: "avatar",
          },
        },
        {
          title: this.$t("enseignant.prenom"),
          dataIndex: "employee.firstName",
          key: "firstName",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "firstName",
          },
          onFilter: (value, record) =>
            record.employee.firstName
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("enseignant.nom"),
          dataIndex: "employee.lastName",
          key: "lastName",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "lastName",
          },
          onFilter: (value, record) =>
            record.employee.lastName
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("enseignant.matiere"),
          dataIndex: "subjects",
          key: "subjects",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "subject",
          },
          onFilter: (value, record) =>
            record.subjects.some((s) => {
              return s.name.toLowerCase().includes(value.toLowerCase());
            }),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("enseignant.specialite"),
          dataIndex: "speciality",
          key: "speciality",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "speciality",
          },
          onFilter: (value, record) =>
            record.speciality.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("enseignant.grade"),
          dataIndex: "grade",
          key: "grade",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "grade",
          },
          onFilter: (value, record) =>
            record.phone.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("enseignant.mdp"),
          scopedSlots: { customRender: "password" },
          fixed: "right",
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "delete",
          scopedSlots: { customRender: "delete" },
          fixed: "right",
        },
      ],
      visibleModalPassword: false,
      changePasswordProps: {
        email: "email",
        password: "Mot de passe",
      },
      activeTeacherId: "",
      activeTeacher: { employee: { firstName: "", lastName: "" } },
      GeneratedPassword: Math.random().toString(36).slice(2, 10),
    };
  },
  methods: {
    validateNoWhitespace(rule, value, callback) {
      if (/\s/.test(value)) {
        callback(new Error("Username cannot contain whitespace"));
      } else {
        callback();
      }
    },
    moment,
    updatePassword(e) {
      this.activeTeacherId = e._id;
      this.activeTeacher = e;

      this.changePasswordProps = {
        email: e.email,
        password: e.tp ? atob(e.tp) : "",
      };

      this.visibleModalPassword = true;
    },
    updateEnseignant(e) {
      e.preventDefault();
      this.updateForm.validateFieldsAndScroll((err, values) => {
        console.log(values);
        if (!err) {
          this.loadingEnseignant = true;
          const id = this.updateData._id;
          apiClient
            .patch(`/teachers/${id}`, { data: values })
            .then((res) => {
              this.data = this.data.map((elem) => {
                if (elem._id == id) {
                  elem.subjects = this.subjectsList.filter((sub) =>
                    values.subjects.includes(sub._id)
                  );
                  elem.grade = values.grade;
                  elem.speciality = values.speciality;
                }
                return elem;
              });
              this.$message.success(this.$t("success.enseignantMSA"));
              this.visibleUpdateData = false;
              this.updateForm.resetFields();
            })
            .catch(() => this.$message.error(this.$t("error.enseignantNonMSA")))
            .finally(() => (this.loadingEnseignant = false));
        }
      });
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    async edit(record) {
      this.updateData = record;
      let subs = [];
      await record.subjects.forEach((sub) => {
        subs.push(sub._id);
      });
      this.updateData.needValidation =
        record.needValidation == true ? "true" : "false";
      this.updateData.subs = subs;
      this.visibleUpdateData = true;
    },
    profile(record) {
      this.updateData = record;
      this.visibleProfileData = true;
      this.profileDataLoading = true;
      this.classroomAffectationProfile = [];
      apiClient
        .get(`/teachers/classroomAffectations/${record._id}`)
        .then((res) => {
          this.classroomAffectationProfile = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.profileDataLoading = false));
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    onDelete(key) {
      apiClient
        .patch("/teachers/" + key, {
          data: {
            status: "inactive",
          },
        })
        .then(() => {
          let teacher = this.data.find((item) => item.key == key);
          if (teacher.employee && teacher.employee._id)
            apiClient.patch("/employees/" + teacher.employee._id, {
              status: "inactive",
            });
          this.$message.success(this.$t("success.enseignantSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() =>
          this.$message.success(this.$t("success.enseignantNonSupp"))
        );
    },
    addEnseignant(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        values.isA_Teacher = true;
        const needValidation = values.needValidation == "true" ? true : false;
        values.needValidation = needValidation;
        if (!err) {
          const classrooms = values.classrooms;
          delete values.classrooms;
          this.loadingEnseignant = true;
          await apiClient
            .put("/teachers", values)
            .then((res) => {
              this.data.push({ ...res.data, key: res.data._id });

              apiClient
                .post("/teachers/affectationClassrooms/" + res.data._id, {
                  classrooms,
                })
                .then((res) => {
                  this.$message.success(this.$t("success.enseignantAjout"));
                  this.handleCancel();
                })
                .catch((e) => {
                  console.error(e);
                  this.$message.error(e.response.data.message);
                });
            })
            .catch((e) => {
              console.log(e);
              this.$message.error(e.response.data.message);
            })
            .finally(() => (this.loadingEnseignant = false));
        }
      });
    },
    handleCancel(e = null) {
      this.form.resetFields();
      this.visibleData = false;
      this.visibleUpdateData = false;
      this.visibleProfileData = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    generatePdf() {
      this.$gtag.event("Imp tableau teachers", {
        event_category: "Impression PDF",
        event_label: "Personnel - ListeEnseignants section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");

      var date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(20);
      doc.text(80, 70, "Liste des Enseignants");
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      var counter = 1;
      let somme = 0;
      for (let i = 0; i < this.data.length; i++) {
        arr.push(counter);
        // arr.push(this.data[i].matricule);
        arr.push(
          this.data[i].employee.firstName + " " + this.data[i].employee.lastName
        );
        let sub = "";
        for (let j = 0; j < this.data[i].subjects.length; j++) {
          sub = sub + this.data[i].subjects[j].name + "\n";
        }
        arr.push(sub);
        arr.push(this.data[i].speciality);
        arr.push(this.data[i].grade);
        array.push(arr);
        arr = [];
        counter++;
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        styles: { font: "Amiri" },
        margin: { top: 80 },
        head: [["Num", "Nom complet", "Matière", "Spécialité", "Niveau"]],
        body: array,
        foot: [],
      });

      doc.save("Liste_Enseignant.pdf");
    },
    showModal() {
      this.visibleData = true;
    },
    onChange(e) {},
    onChangeParent(e) {},
    handleCancelImage() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeSelect(value) {},
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.editable-row-operations a {
  margin-right: 8px;
}

.switch {
  float: right;
  margin-right: 15px;
}

.bottom {
  margin-top: 7px;
}

label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}

.span {
  /* display: flex;  */
  flex-direction: row;
}

.space {
  margin-left: 200px;
}

.inp {
  margin-left: 0px;
}

.clearfix {
  float: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
</style>
